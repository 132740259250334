@font-face {
  font-family: 'Uni Neue';
  src: local('Uni Neue Heavy'), local('UniNeueHeavy'),  url('../fonts/UniNeueHeavy.woff2') format('woff2'), url('../fonts/UniNeueHeavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Uni Neue Book';
  src: local('Uni Neue Book'), local('UniNeueBook'), url('../fonts/UniNeueBook.woff2') format('woff2'), url('../fonts/UniNeueBook.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Uni Neue';
  src: local('Uni Neue Regular'), local('UniNeueRegular'), url('../fonts/UniNeueRegular.woff2') format('woff2'), url('../fonts/UniNeueRegular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Uni Neue';
  src: local('Uni Neue Light'), local('UniNeueLight'), url('../fonts/UniNeueLight.woff2') format('woff2'), url('../fonts/UniNeueLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Uni Neue';
  src: local('Uni Neue Bold'), local('UniNeueBold'), url('../fonts/UniNeueBold.woff2') format('woff2'), url('../fonts/UniNeueBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Uni Neue';
  src: local('Uni Neue Thin'), local('UniNeueThin'), url('../fonts/UniNeueThin.woff2') format('woff2'), url('../fonts/UniNeueThin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Uni Neue';
  src: local('Uni Neue Black'), local('UniNeueBlack'), url('../fonts/UniNeueBlack.woff2') format('woff2'), url('../fonts/UniNeueBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Uni Neue';
  color: #000;
  font-weight: 300;
}

.show-on-mobile{
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
  }
}
header{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.5vw;
  width: 100%;
  padding: 0 1.56vw;
  box-sizing: border-box;
  border-bottom: solid 1px #004D8D;
  @media screen and (max-width: 800px) {
    //display: block;
    height: 120px;
    padding: 23px 20px;
    border: 0;
    margin-bottom: 5px;
  }
  #products-menu{
    position: relative;
    display: block;
    img{
      height: 1.71vw;
      @media screen and (max-width: 800px) {
        height: 24px;
      }

    }
    @media screen and (max-width: 800px) {
      a{
        margin-right: 0;
      }
    }

    #products-submenu{
      position: absolute;
      display: none;
      right: 20px;
      top: 0;
      background: rgba(255, 255, 255, 1);
      z-index: 100;
      border-radius: 2.34vw;
      margin: -1.62vw -2.34vw;
      padding: 1.56vw 2.5vw 2.5vw;
      border: 1px solid #004D8D;
      box-sizing: border-box;
      width: 26.4vw;
      @media screen and (max-width: 800px) {
        //margin: -10px -72px;
        right: 0;
        padding: 10px;
        width: 200px;
      }
      &.active{
        display: block;
      }
      .products-submenu-title{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.87vw;
        line-height: 2.5vw;
        margin-bottom: 2.65vw;
        cursor: pointer;
        @media screen and (max-width: 800px) {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 20px;
        }
        img{
          margin-right: 2.08vw;
          @media screen and (max-width: 800px) {
            margin-right: 16px;
          }
        }
        &:hover{
          color: #004D8D;
        }
      }
      .products-submenu-items{
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1.95vw;
        .products-submenu-item{
          .products-submenu-item-title{
            position: relative;
            display: block;
            line-height: 1.95vw;
            font-size: 1.4vw;
            font-weight: 500;
            cursor: pointer;
            @media screen and (max-width: 800px) {
              font-size: 12px;
              line-height: 20px;
            }
            svg{
              position: absolute;
              display: block;
              right: 0;
              height: 0.85vw;
              top: calc(50% - 0.42vw);
              @media screen and (max-width: 800px) {
                height: 8px;
                top: calc(50% - 4px);
              }
            }
            &:hover{
              color: #004D8D;
              svg path{
                fill: #004D8D;
              }
            }
          }
          .products-submenu-item-links{
            display: none;
            margin-bottom: -0.3vw;
            a{
              position: relative;
              display: block;
              color: #000;
              text-decoration: none;
              font-size: 1.25vw;
              line-height: 1.72vw;
              margin-top: 0.6vw;
              @media screen and (max-width: 800px) {
                font-size: 12px;
                line-height: 16px;
                margin-top: 5px;
              }
              &:hover{
                color: #004D8D;
              }
            }
          }

          &.active{
            .products-submenu-item-links{
              display: block;
            }
          }
        }

      }
    }
  }

  a{
    position: relative;
    display: block;
    font-size: 1.87vw;
    line-height: 2.5vw;
    color: #000;
    text-decoration: none;
    &#contacts-link{
      display: none;
    }
    @media screen and (max-width: 800px) {
      display: block;
      font-size: 18px;
      line-height: 24px;
      margin-right: 40px;
      width: auto;
      &#contacts-link{
        display: none;
      }
      &:first-child{
        //display: block;
        img{
          &#header-logo{
            height: 70px !important;
          }
          //margin-bottom: 22px;
        }
      }
    }
    img{
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.32vw;
      top:-0.2vw;
    }
    &:hover{
      color: #00A2DE;
    }
    &#language{
      display: none;
      width: 18.75vw;
      height: 3.75vw;
      line-height: 3.75vw;
      border: 1px solid #004D8D;
      box-sizing: border-box;
      font-size: 1.4vw;
      text-align: center;
      span:last-child{
        display: none;
      }
      @media screen and (max-width: 800px) {
        display: none;
        justify-content: center;
        align-items: center;
        width: 91px;
        height: 48px;
        position: absolute;
        right: 20px;
        top: 34px;
        margin-right: 0;
        span{
          display: none;
          font-size: 14px;
        }

        span:last-child{
          display: inline-block;
        }

      }

      img{
        height: 2.26vw;
        width: auto;
        @media screen and (max-width: 800px) {
          height: 29px;
          margin-left: -5px;
        }

      }
      &:hover{
        border: 1px solid #00A2DE;
      }
    }
  }

}

#teletext{
  position: relative;
  display: flex;
  height: 3.75vw;
  background: #004D8D;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    height: 40px;
    //margin-top: 20px;
  }

  .teletext-blocks{
    position: relative;
    display: block;
    white-space: nowrap;
    animation: marquee 20s infinite linear;
    .teletext-block{
      position: relative;
      display: inline-block;
      margin-right: 2.34vw;
      font-size: 1.4vw;
      line-height: 3.75vw;
      padding: 0 1.56vw;
      color: #fff;
      white-space: nowrap;
      @media screen and (max-width: 800px) {
        font-size: 14px;
        line-height: 40px;
        padding: 0 30px;
      }
    }
  }
}
@keyframes marquee{
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-33.333%)
  }
}


section{
  &#home-web{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 15.23vw);
    min-height: 38vw;
    @media screen and (max-width: 800px) {
      width: 100%;
      min-height: 0;
      height: auto;
    }

    #center-web{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26.94vw;
      width: 23.87vw;
      top: 2vw;
      @media screen and (max-width: 800px) {
        display: none;
      }
      img{
        position: absolute;
        display: block;
        opacity: 0;
        width: 100%;
        height: 100%;
        animation: animateWeb 1s forwards cubic-bezier(.25,.67,.51,1.2);
        animation-delay: 0.2s;
        &:nth-child(2){
          position: relative;
          display: block;
          height: 17.18vw;
          animation: animateLogo 0.6s forwards cubic-bezier(.05,.91,.44,.93);
        }
      }
    }
    @keyframes animateWeb {
      0% {
        opacity: 0;
        transform: rotate(0deg) scale(0);
      }
      100% {
        opacity: 1;
        transform: rotate(360deg) scale(1);
      }
    }
    @keyframes animateLogo {
      0% {
        opacity: 0;
        transform: scale(0);
      }
      30% {
        opacity: 1;
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    #home-web-links{
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      @media screen and (max-width: 800px) {
        display: none;
      }

      a{
        position: absolute;
        display: block;
        padding: 0 1.17vw;
        left: 50%;
        height: 4.29vw;
        border-radius: 0.77vw;
        box-sizing: border-box;
        border: rgba(0, 162, 222, 0.09) solid 2px;
        font-size: 2.03vw;
        line-height: 4.29vw;
        font-weight: bold;
        color: #000;
        text-decoration: none;
        white-space: nowrap;
        animation: homeLinkAnimate 0.4s forwards cubic-bezier(.25,.67,.51,1.52);

        &:hover{
          border: rgba(0, 162, 222, 1) solid 2px;
          color: rgba(0, 162, 222, 1);
        }
        img{
          position: relative;
          display: inline-block;
          height: 2.34vw;
          width: auto;
          margin-right: 0.73vw;
          vertical-align: middle;
          top: -0.24vw;
        }

        &:nth-child(1){
          top: calc(50% - 17vw);
          width: 17.51vw;
          margin-left: -9.25vw;
          opacity: 0;
          animation-delay: 0.6s;
        }
        &:nth-child(2){
          top: calc(50% - 6.15vw);
          width: 27.57vw;
          margin-left: 12.93vw;
          opacity: 0;
          animation-delay: 0.6s;
        }
        &:nth-child(3){
          top: calc(50% + 5.65vw);
          width: 25.93vw;
          margin-left: 12.93vw;
          opacity: 0;
          animation-delay: 0.6s;
        }
        &:nth-child(4){
          top: 34.99vw;
          width: 38.90vw;
          margin-left: -19.45vw;
          opacity: 0;
          animation-delay: 0.6s;
        }
        &:nth-child(5){
          top: calc(50% + 5.65vw);
          width: 22.42vw;
          margin-left: -35.26vw;
          opacity: 0;
          animation-delay: 0.6s;
        }
        &:nth-child(6){
          top: calc(50% - 6.15vw);
          width: 33.62vw;
          margin-left: -46.53vw;
          opacity: 0;
          animation-delay: 0.6s;
        }
      }
      @keyframes homeLinkAnimate {
        100% {
          opacity: 1;
          transform: translateX(0) translateY(0);
        }
      }

    }
  }

  &#home-mobile-list{
    display: none;
    min-height: calc(100vh - 380px);
    @media screen and (max-width: 800px) {
      position: relative;
      display: flex;
      align-items: center;
    }

    //&:after{
    //  position: absolute;
    //  display: block;
    //  width: 100%;
    //  height: 140px;
    //  pointer-events: none;
    //  left: 0;
    //  top: 0;
    //  z-index: 1;
    //  content: "";
    //  background: linear-gradient(180deg, #fff 30%, rgba(255, 255, 255, 0));
    //}
    //&:before{
    //  position: absolute;
    //  display: block;
    //  width: 100%;
    //  height: 140px;
    //  pointer-events: none;
    //  left: 0;
    //  bottom: 0;
    //  z-index: 1;
    //  content: "";
    //  background: linear-gradient(0deg, #fff 30%, rgba(255, 255, 255, 0));
    //}

    #home-mobile-list-scroll{
      position: relative;
      height: auto;
      overflow: hidden;
      margin: 20px 0;
      //overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      #home-mobile-list-line{
        position: relative;
        border-left: #00A2DE solid 3px;
        margin: 0 20px;
        //padding: 70px 0;
        a{
          position: relative;
          display: block;
          border: 2px solid #00a2de;
          border-radius: 10px;
          box-sizing: border-box;
          color: #000;
          font-weight: 600;
          height: 42px;
          line-height: 38px;
          margin: 20px 0 20px 10px;
          padding-left: 20px;
          text-decoration: none;
          width: calc(100vw - 50px);


          &:before{
            border: 3px solid #00a2de;
            border-radius: 6px;
            box-sizing: border-box;
            content: "";
            display: block;
            height: 10px;
            position: absolute;
            top: calc(50% - 5px);
            width: 10px;
            background: #fff;
            left: -19px;
          }

          img{
            position: relative;
            display: inline-block;
            vertical-align: middle;
            top: -2px;
            margin-right: 10px;
            height: 20px;
          }
        }
      }
    }
  }

  &#services{
    position: relative;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    min-height: calc(100vh - 11.48vw);
    padding-bottom: 5.46vw;
    box-sizing: border-box;
    overflow: hidden;
    &.page-with-bg{
      background: url(../img/big_bg.png) no-repeat 0 100%;
      background-size: 100% auto;
      @media screen and (max-width: 800px) {
        background: none;
      }
      .services-texts{
        .service-sblocks{
          .service-sblock{
            margin-top: 5.31vw;
            @media screen and (max-width: 800px) {
              margin-top: 50px;
            }

          }
        }
      }
    }
    #under-construction{
      position: relative;
      display: block;
      font-size: 2.025vw;
      text-align: center;
      a{
        position: relative;
        display: inline-block;
        padding: 0 2.81vw;
        margin-top: 1.6875vw;
        height: 3.74vw;
        line-height: 3.74vw;
        border: 1px solid #004D8D;
        color: #000;
        text-decoration: none;
        box-sizing: border-box;
        font-size: 1.4vw;
        text-align: center;
        &:hover{
          border: 1px solid #00A2DE;
          color: #00A2DE;
        }
      }
    }

    #progress-line{
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2.1vw 0 2.34vw 1.558vw;
      height: 5.155vw;
      @media screen and (max-width: 800px) {
        height: 251px;
        padding-left: calc(50% - 10px);
        margin: 0;
        margin-bottom: 15px;
        background: url(../img/mobile_logo_bg.png) no-repeat 0 50%;
        background-size: 183px;
      }

      #progress-logo{
        position: relative;
        display: block;
        @media screen and (max-width: 800px) {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(50% - 10px);
          left: 0;
          top: 0;
          height: 251px;
          mix-blend-mode: multiply;
        }

        img{
          width: 9.1vw;
          @media screen and (max-width: 800px) {
            height: 69px;
            width: auto;
          }
        }
      }
      #progress-list{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.466vw;
        padding: 0 2.34vw 0 4vw;
        top: 1vw;
        background: #004D8D;
        box-sizing: border-box;
        width: calc(100% - 10.25vw);
        @media screen and (max-width: 800px) {
          flex-direction: column;
          padding: 0;
          height: 251px;
          width: 3px;
          justify-content: space-around;
        }

        .progress-point{
          position: relative;
          display: block;
          width: 1.248vw;
          height: 1.248vw;
          border-radius: 1.125vw;
          border: #004D8D solid 0.4668vw;
          background: #fff;
          box-sizing: border-box;
          @media screen and (max-width: 800px) {
            display: none;
            &.with-info{
              display: flex;
              align-items: center;
            }
          }

          span{
            bottom: 2vw;
            display: block;
            font-size: 1.25vw;
            left: calc(50% - 10vw);
            line-height: 1.4vw;
            position: absolute;
            text-align: center;
            font-weight: 400;
            width: 20vw;
            cursor: pointer;
            @media screen and (max-width: 800px) {
              font-size: 12px;
              left: 36px;
              line-height: 14px;
              position: absolute;
              text-align: left;
              top: 0;
              white-space: normal;
              width: 130px;
              /* width: 130px !important; */
              display: flex;
              transform: translateY(-50%);
              height: auto;
              bottom: auto;
            }
          }
          a{
            position: absolute;
            display: block;
            background: #004D8D;
            width: 2.578vw;
            height: 2.578vw;
            border: #004D8D solid 1px;
            left: calc(50% - 1.288vw);
            top: calc(50% - 1.288vw);
            line-height: 2.475vw;
            border-radius: 1.687vw;
            text-align: center;
            font-size: 1.558vw;
            font-weight: bold;
            color: #fff;
            box-sizing: border-box;
            text-decoration: none;
            @media screen and (max-width: 800px) {
              width: 22px;
              height: 22px;
              top: calc(50% - 11px);
              left: calc(50% - 11px);
              border-radius: 22px;
              line-height: 18px;
              font-size: 16px;
            }
          }
          &.active{
            a{
              background: #00A2DE;
            }
          }
        }

        &.red-progress{
          background: #E74624;
          .progress-point{
            border: #E74624 solid 0.4668vw;
            background: #fff;
            a{
              position: absolute;
              display: block;
              background: #E74624;
              width: 2.578vw;
              height: 2.578vw;
              border: #fff solid 1px;
              left: calc(50% - 1.288vw);
              top: calc(50% - 1.288vw);
              line-height: 2.475vw;
              border-radius: 1.687vw;
              text-align: center;
              font-size: 1.558vw;
              font-weight: bold;
              color: #fff;
              box-sizing: border-box;
              text-decoration: none;
              @media screen and (max-width: 800px) {
                width: 22px;
                height: 22px;
                top: calc(50% - 11px);
                left: calc(50% - 11px);
                border-radius: 22px;
                line-height: 18px;
                font-size: 16px;
              }
            }
            &.active{
              a{
                background: #F7B01F;
              }
            }
          }

        }
      }
    }

    .services-points-block{
      position: relative;
      display: block;
      margin: 2.34vw 1.558vw 3.667vw 3.35vw;
      font-size: 1.715vw;
      line-height: 1.873vw;
      @media screen and (max-width: 800px) {
        font-size: 18px;
        line-height: 24px;
        margin: 0 20px;
        margin-top: 20px;
      }

      .services-points-line{
        position: relative;
        display: flex;
        justify-content: space-between;
        border-top: #00A2DE solid 0.23vw;
        margin: 2.34vw -1.558vw 0 -3.358vw;
        padding: 0 1.558vw 0 3.358vw;
        box-sizing: border-box;
        @media screen and (max-width: 800px) {
          flex-direction: column;
          border-top: 0;
          border-left: #00A2DE solid 3px;
          margin: 0 10px;
          margin-top: 26px;
          margin-bottom: 30px;
          padding: 0;
        }
        .services-point{
          position: relative;
          display: block;
          width: 33.33%;
          text-align: center;
          font-size: 1.4vw;
          margin-top: 1.87vw;
          @media screen and (max-width: 800px) {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 25px 0;
            padding-left: 24px;
            font-size: 14px;
            line-height: 19px;
            text-align: left;
            margin: 0;
            br{
              position: relative;
              display: inline-block;
              width: 6px;
              content: "&nbsp;";
            }
          }

          &:before{
            position: absolute;
            display: block;
            content: "";
            width: 1.95vw;
            height: 1.95vw;
            border-radius: 1.95vw;
            background: #00A2DE;
            left: calc(50% - 0.973vw);
            top: -2.98vw;
            @media screen and (max-width: 800px) {
              height: 11px;
              left: -7px;
              top: calc(50% - 5px);
              width: 11px;
            }

          }
        }
      }
    }
    .progress-tech{
      position: relative;
      display: block;
      margin: 0 1.558vw;
      margin-top: 8.43vw;
      margin-bottom: 9.14vw;
      @media screen and (max-width: 800px) {
        margin: 20px 24px;
      }

      #progress-tech-list{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.466vw;
        padding: 0 8vw 0 7.3vw;
        background: #F7AF1E;
        box-sizing: border-box;
        width: 100%;
        @media screen and (max-width: 800px) {
          height: auto;
          padding: 25px 0;
          width: 3px;
          gap: 50px;
          background: #E74625;
          flex-direction: column;
        }
        .progress-point{
          position: relative;
          display: block;
          width: 1.248vw;
          height: 1.248vw;
          border-radius: 1.125vw;
          border: #F7AF1E solid 0.4668vw;
          background: #fff;
          box-sizing: border-box;
          @media screen and (max-width: 800px) {
            display: none;
            width: 11px;
            height: 11px;
            border-radius: 6px;
            background: #E74625;
            border: none;
            &.with-info{
              display: block;
            }
          }
          span{
            position: absolute;
            display: block;
            background: #E74625;
            width: 2.578vw;
            height: 2.578vw;
            border: #fff solid 1px;
            left: calc(50% - 1.288vw);
            top: calc(50% - 1.288vw);
            line-height: 2.475vw;
            border-radius: 1.687vw;
            text-align: center;
            font-size: 1.558vw;
            font-weight: bold;
            color: #fff;
            box-sizing: border-box;
            text-decoration: none;
            @media screen and (max-width: 800px) {
              color: rgba(255, 255, 255, 0);
              background: rgba(255, 255, 255, 0);
              border: none
            }

            span{
              background: none;
              bottom: 3.7vw;
              color: #000;
              display: block;
              font-size: 1.25vw;
              font-weight: 400;
              left: calc(50% - 7vw);
              line-height: 1.71vw;
              position: absolute;
              text-align: center;
              width: 14vw;
              top: auto;
              @media screen and (max-width: 800px) {
                left: 20px;
                text-align: left;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                white-space: nowrap;
                top: calc(50% - 10px);
                br{
                  position: relative;
                  display: inline-block;
                  content: " ";
                  width: 4px;
                }
              }
            }
          }
        }
      }

    }

    .services-texts{
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: 0 1.558vw 0 3.358vw;
      font-size: 1.4vw;
      line-height: 1.895vw;
      @media screen and (max-width: 800px) {
        flex-direction: column;
        font-size: 14px;
        line-height: 19px;
        margin: 0 20px;
        &.mobile-no-margin{
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
        &.mobile-wrap{
          margin-top: 25px !important;
          margin-bottom: 25px !important;
          flex-wrap: wrap !important;
          flex-direction: row !important;
          img{
            width: 42px !important;
            height: 42px;
            object-fit: contain;
          }
        }
      }

      .tech-footer{
        margin-top: 4.76vw;
        margin-bottom: 4.7vw;
      }

      &.no-margin{
        margin: 0;
      }

      #business-block{
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 3.9vw;
        img{
          width: 50%;
          @media screen and (max-width: 800px) {
            width: 100%;
          }
        }
        .business-block-text{
          width: 50%;
        }
        margin-bottom: 4.68vw;
        @media screen and (max-width: 800px) {
          display: block;
          .business-block-text{
            width: 100%;
          }
        }

      }

      .left-border-block{
        position: relative;
        padding-left: 1.248vw;
        border-left: #00A2DE solid 2px;
        box-sizing: border-box;
        font-size: 1.248vw;
        line-height: 1.687vw;
        margin-bottom: 2vw;
        @media screen and (max-width: 800px) {
          font-size: 14px;
          line-height: 19px;
          padding-left: 27px;
          width: 100% !important;
          box-sizing: border-box;
          margin-bottom: 20px;
        }
      }

      .service-sblock-title{
        position: relative;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 1.873vw;
        line-height: 1.873vw;
        padding-bottom:1.873vw;
        font-weight: bold;
        @media screen and (max-width: 800px) {
          font-size: 18px;
          line-height: 24px;
          br{
            position: relative;
            display: inline-block;
            width: 6px;
            content: "&nbsp;";
          }

        }
      }
      &.no-margin{
        .service-sblock-title{
          padding-bottom:4vw;
        }
      }

      .service-sblocks{
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-right: 1.839vw;
        box-sizing: border-box;
        @media screen and (max-width: 800px) {
          padding: 0;
          flex-direction: column;
          width: 100%;
        }
        .service-sblock-empty{
          width: calc(16% - 1.558vw);
          @media screen and (max-width: 800px) {
            width: 100%;
          }
          &.third-part{
            width: calc(33.333% - 1.558vw);
            @media screen and (max-width: 800px) {
              width: 100%;
            }
          }
        }

        .service-sblock{
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(25% - 1.558vw);
          min-height: 11.95vw;
          padding: 0 1.125vw;
          padding-top: 4.84vw;
          padding-bottom: 1.558vw;
          box-sizing: border-box;
          font-size: 1.248vw;
          text-align: center;
          white-space: normal;
          background: #F4F4F4;
          border-radius: 0.776vw;
          margin-top: 4.29vw;
          font-weight: 400;
          @media screen and (max-width: 800px) {
            width: 100%;
            font-size: 14px;
            line-height: 19px;
            padding-bottom: 15px;
            padding-top: 45px;
            margin-top: 48px;
            border-radius: 10px;
          }
          &.third-part{
            width: calc(33.333% - 1.558vw);
            @media screen and (max-width: 800px) {
              width: 100%;
            }
          }

          img{
            position: absolute;
            display: block;
            width: 5.928vw;
            height: 5.928vw;
            top: -2.96vw;
            left: calc(50% - 2.96vw);
            @media screen and (max-width: 800px) {
              width: 76px;
              height: 76px;
              top: -38px;
              left: calc(50% - 38px)
            }

          }
        }
        .service-sblock-left{
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: calc(33.333% - 1.558vw);
          box-sizing: border-box;
          font-size: 1.248vw;
          text-align: left;
          white-space: normal;
          margin-bottom: 4.29vw;
          gap: 1.01vw;
          @media screen and (max-width: 800px) {
            width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 20px;
            gap: 13px;
          }

          img{
            width: 5.928vw;
            height: 5.928vw;
            @media screen and (max-width: 800px) {
              width: 76px;
              height: 76px;
            }
          }
        }
      }


      .omni-item{
        position: relative;
        display: block;
        text-align: center;
        @media screen and (max-width: 800px) {
          width: 45% !important;
        }
        img{
          width: 3.59vw;
        }
        margin-top: 2.34vw;
        margin-bottom: 2.9vw;
      }

      .services-text-block{
        position: relative;
        display: block;
        flex-direction: column;
        box-sizing: border-box;

        @media screen and (max-width: 800px) {
          width: 100% !important;
          #ipad{
            margin-bottom: 20px;
          }
        }
        &.border-bottom{
          border-bottom: #E7E7E7 dotted 1px;
          margin-bottom: 2.187vw;
        }

        .audit-icon-items{
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: -2vw;
          margin-bottom: 5vw;
          @media screen and (max-width: 800px) {
            flex-direction: column;
          }

          .audit-icon-item{
            position: relative;
            display: flex;
            align-items: center;
            gap: 1.32vw;
            height: 5.31vw;
            width: 46%;
            margin-bottom: 1.32vw;
            @media screen and (max-width: 800px) {
              width: 100%;
              height: auto;
              margin-bottom: 23px;
              padding-left: 53px;
              box-sizing: border-box;
            }
            img{
              width: 3.125vw;
              height: 3.125vw;
              @media screen and (max-width: 800px) {
                position: absolute;
                display: block;
                width: 40px;
                height: 40px;
                left: 0;
                top: 6px;
              }
            }
            span{
              font-size: 1.4vw;
              line-height: 1.875vw;
              @media screen and (max-width: 800px) {
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }

        .services-text-title{
          position: relative;
          display: inline-block;
          height: 3.825vw;
          line-height: 3.825vw;
          padding: 0 1.248vw;
          font-size: 1.873vw;
          color: #fff;
          font-weight: bold;
          background: #004D8D;
          border-radius: 0 0.776vw 0 0.776vw;
          margin-bottom: 2.025vw;
          white-space: nowrap;
          @media screen and (max-width: 800px) {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            left: 0 !important;
            height: auto;
            padding: 7px 15px;
            margin-bottom: 19px;
            border-radius: 0 10px 0 10px;
            white-space: normal;
            max-width: 80%;
            margin-top: 20px;
          }
        }
        &:first-child{
          .services-text-title{
            left: -1.79vw;
            @media screen and (max-width: 800px) {
              left: 0 !important;
            }
          }
        }

        .title-text{
          font-size: 1.718vw;
          line-height: 2.34vw;
          @media screen and (max-width: 800px) {
            font-size: 18px;
            line-height: 24px;
          }
          &.title-text18{
            font-size: 1.4vw;

            @media screen and (max-width: 800px) {
              font-weight: 700;
              font-size: 15px;
              line-height: 20px;
              margin-bottom: 11px !important;
              position: relative;
              display: block;
              padding-left: 0px !important;

              img{
                display: none;
              }
            }
            .yellow-check{
              position: relative;
              display: block;
              width: 4.68vw;
            }
            &.yellow-title{
              color: #F7B01F !important;
            }
          }
        }

        .data-360-check{
          position: relative;
          display: block;
          padding-left: 2.655vw;
          margin-bottom: 1.89vw;
          left: -1.79vw;
          &:before{
            position: absolute;
            display: block;
            content: "";
            width: 1.794vw;
            height: 1.794vw;
            background: url(../img/checks.png) no-repeat;
            background-size: contain;
            left:0;
            top: 0;
          }
        }
        .polygon-item{
          position: relative;
          display: block;
          padding-left: 1.95vw;
          margin-bottom: 0.7vw;
          @media screen and (max-width: 800px) {
            padding-left: 33px;
            padding-bottom: 10px;
          }

          &:before{
            position: absolute;
            display: block;
            content: "";
            width: 1.248vw;
            height: 1.248vw;
            background: url(../img/polygon.svg) no-repeat;
            background-size: contain;
            left:0;
            top: 0.28vw;
            @media screen and (max-width: 800px) {
              width: 16px;
              height: 16px;
            }
          }
          &.light{
            &:before {
              background: url(../img/polygon_light.svg) no-repeat;
              background-size: contain;
            }
          }
        }
        strong{
          position: relative;
          display: inline-block;
          color: #00A2DE;
          font-size: 1.558vw;
          line-height: 2.109vw;
          @media screen and (max-width: 800px) {
            font-size: 18px;
            line-height: 25px;
          }
        }

        .columns-title{
          font-size: 1.715vw;
          line-height: 1.92vw;
          margin-top: 2.34vw;
          margin-bottom: 3.825vw;
          font-weight: 600;
          @media screen and (max-width: 800px) {
            font-size: 15px;
            line-height: 24px;
            small{
              margin-top: 8px  !important;
              font-size: 14px !important;
              line-height: 18px !important;
            }
          }
          &.margin20{
            margin-bottom: 2.03vw;
          }
          small{
            font-size: 1.4vw;
            font-weight: 300;
            line-height: 1.25vw;
            margin-top: 1vw;
            position: relative;
            display: block;
          }
        }
        .businnes-column{
          .service-columns{
            .service-column{
              width: 27.57vw;
              @media screen and (max-width: 800px) {
                width: auto;
              }
              b{
                margin-bottom: 1.4vw;
                position: relative;
                display: block;
              }
            }
          }
        }
        .services-text-block.red-column{
          .service-column{
            &.half-block{
              width: 44%;
              @media screen and (max-width: 800px) {
                width: auto;
              }
            }

            &.with-arrow:after{
              position: absolute;
              background: url(../img/yellow_arrow.svg) no-repeat 0 0;
              background-size: contain;
              content: "";
              display: block;
              height: 6.48vw;
              left: -6.4vw;
              top: -0.2vw;
              width: 3.75vw;


              @media screen and (max-width: 800px) {
                height: 83px;
                left: calc(50% - 25px);
                position: absolute;
                top: -90px;
                width: 48px;
                transform: rotate(90deg);
              }
            }
            .title-text{
              color: #E74624;
              margin-bottom: 1vw;
              position: relative;
              display: block;
              @media screen and (max-width: 800px) {
                margin-bottom: 11px;
              }
              img{
                  position: absolute;
                  width: 50.625vw;
                  top: 0.3vw;
                  left: 14vw;
                }
            }
            .polygon-item{
              &:before {
                background: url(../img/polygon_red.svg) no-repeat;
                background-size: contain;
              }
            }

          }
        }
        .service-columns{
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          @media screen and (max-width: 800px) {
            flex-direction: column;
          }

          .service-column{
            position: relative;
            display: block;
            width: 28.57vw;
            font-size: 1.248vw;
            margin-bottom: 3.588vw;
            font-weight: 400;
            &.no-margin{
              margin-bottom: 0;
            }
            @media screen and (max-width: 800px) {
              width: 100%;
              font-size: 14px;
              line-height: 19px;
              margin-bottom: 90px;
              &.no-margin-mobile{
                margin-bottom: 30px;
              }
              &:last-child{
                margin-bottom: 30px;
              }
              &.mobile-format{
                .yellow-check{
                  position: absolute;
                  left: 0;
                  top: 0;
                }
                padding-left: 80px;
                width: auto;
                br{
                  position: relative;
                  display: inline-block;
                  width: 6px;
                  content: "&nbsp;";
                }

              }
            }
            strong{
              position: relative;
              font-size: 2.81vw;
              margin-bottom: 0.62vw;
              @media screen and (max-width: 800px) {
                font-size: 36px;
                line-height: 24px;
                margin-bottom: 17px;
              }
            }
            .service-column-number-title{
              position: relative;
              display: block;
              font-weight:600;
              font-size: 1.4vw;
              margin-bottom: 0.62vw;
              @media screen and (max-width: 800px) {
                font-size: 15px;
                line-height: 24px;
                margin-bottom: 15px;
              }
            }
          }
        }
        .service-days{
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 2vw;
          @media screen and (max-width: 800px) {
            flex-direction: column;
            margin-bottom: 30px;
          }
          .service-day-column{
            position: relative;
            display: block;
            text-align: center;
            width: 29.76vw;
            height: 17.73vw;
            font-size: 1.56vw;
            border: 1px solid #E2E2E2;
            border-radius: 0.84vw;
            text-transform: uppercase;
            font-weight: 500;
            @media screen and (max-width: 800px) {
              width: 100%;
              height: 191px;
              border-radius: 10px;
              margin-bottom: 24px;
              &.hide-on-mobile{
                display: none;
              }
              font-size: 16px;
              line-height: 23px;

            }

            .service-day-column-color{
              position: relative;
              display: block;
              margin: 0 auto;
              margin-top: 3.98vw;
              margin-bottom: 2.03vw;
              width: 21.9vw;
              height:5.85vw;
              border-radius: 0.84vw;
              background: #81D2FF;
              font-size: 3.125vw;
              line-height:5.85vw;
              font-weight: 700;
              color: #fff;
              text-transform: none;
              @media screen and (max-width: 800px) {
                width: 237px;
                height: 63px;
                margin-top: 43px;
                margin-bottom: 22px;
                font-size: 33.7449px;
                line-height: 46px;
                border-radius: 8.4px;
              }

              b{
                font-size: 5vw;
                font-weight: 800;
                @media screen and (max-width: 800px) {
                  font-size: 54px;
                  line-height: 63px;
                }

              }
            }
            strong{
              color: #000;
              display: block;
              font-size: 5vw;
              line-height: 7.1vw;
              margin-bottom: -0.84vw;
              position: relative;
              font-weight: 800;


            }
          }
        }
      }
    }

    .tech-index-block{
      position: relative;
      display: block;
      margin: 0 1.558vw 0 3.358vw;
      @media screen and (max-width: 800px) {
        margin: 0 20px 30px;
      }

      .tech-index-title{
        position: relative;
        display: block;
        font-size: 2.109vw;
        font-weight: bold;
        color: #E74625;
        margin-bottom: 3.125vw;
        @media screen and (max-width: 800px) {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
        }

        b{
          position: relative;
          display: block;
          color: #000;
          font-weight: bold;
          font-size: 1.178vw;
          line-height: 1.87vw;
          margin-top: 0.4vw;
          @media screen and (max-width: 800px) {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
          }
        }
      }
    }
  }
}


footer{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.98vw;
  font-size: 1.4vw;
  background: #ECECEC;
  padding: 0 0 0 1.875vw;
  span{
    font-weight: 500;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    font-size: 18px;
    padding: 20px;
    height: 219px;
    box-sizing: border-box;
  }

  .footer-links{
    position: relative;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 800px) {
      display: block;
    }

    a{
      position: relative;
      display: block;
      margin-right: 3.35vw;
      line-height: 4.29vw;
      font-size: 1.4vw;
      color: #000;
      text-decoration: none;
      @media screen and (max-width: 800px) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 15px;
      }

      img{
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.46vw;
        height: 2.18vw;
        width: auto;
        @media screen and (max-width: 800px) {
          display: block;
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }

      }
    }
  }

}

.rules{
  margin: 0 auto;
  max-width: 900px;
  width: calc(100% - 40px);
}
.h2_rules{
  text-transform: uppercase;
  text-align: center;
}
.h1_rules{
  text-align: center;
}
ul {
  list-style-type: '—';
  
}
ul li{
  padding-left: 20px;
}
.marker_none{
  list-style: none;
}
.disc{
  list-style:disc;
}

.cirilic { 
  list-style: none;
  padding-left: 20px;
 }
 .cirilic li{
  padding-left: 10px;
 }
 .cirilic li::before {
  margin-right: 10px;
  width: 1em;
  display: inline-block;
  
 }
 .cirilic li:nth-child(2)::before { content: 'Б)'; }
 .cirilic li:nth-child(1)::before { content: 'А)'; }
 .cirilic li:nth-child(3)::before { content: 'В)'; }
 .cirilic li:nth-child(4)::before { content: 'Г)'; }
 .cirilic li:nth-child(5)::before { content: 'Д)'; }
 .cirilic li:nth-child(6)::before { content: 'Е)'; }
 .cirilic li:nth-child(7)::before { content: 'Ё)'; }
 .cirilic li:nth-child(8)::before { content: 'Ж)'; }
 .cirilic li:nth-child(9)::before { content: 'З)'; }
 .cirilic li:nth-child(10)::before { content: 'И)'; }